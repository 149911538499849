export const PetTag = {
  /** Tag associated with dog product */
  Dog: 'Für Hunde',
  /** Tag associated with cat product */
  Cat: 'Für Katzen',
} as const;

/** ID associated with the product 'Eco Option' */
export const ECO_OPTION_ID =
  process.env.PD_ENV === 'production' ? 28539316338722 : 14891291967530;
