// import { ProductRecommendationResponse } from 'api/foodaid/questions';
import { CollectionResponse } from 'api/product';
import { CollectionProduct } from './collection';

export interface Collection {
  title: string;
  products: CollectionProduct[];
  handle?: string;
}

type transformResponseInput = {
  title?: string;
  collection: Omit<
    CollectionResponse,
    | '_updatedAt'
    | '_updated'
    | 'sk'
    | 'pk'
    | 'missing'
    | 'handle'
    | 'published'
    | 'exportedAt'
  >;
  //| ProductRecommendationResponse['result'];
};

export const transformCollection = ({
  title,
  collection,
}: transformResponseInput): Collection => {
  const products = collection.products.map(
    (product) => new CollectionProduct(product)
  );
  return {
    ...collection,
    products,
    title: title || collection.title,
  };
};
