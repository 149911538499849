import {
  SegmentationIds,
  SEGMENTS_RAW_FILTER_MAP,
} from 'constants/segmentation';
import { useSegmentationContext } from 'contexts/segmentation';
import { useMemo } from 'react';
import useShouldPersonalize from './use-should-personlize';

const SubtitleMap = {
  [SegmentationIds.Intolerance]: 'Speziell für Allergiker',
  [SegmentationIds.Digestion]: 'Ideal bei sensibler Verdauung',
  [SegmentationIds.PickyEater]: 'Gut für wählerische Esser',
  [SegmentationIds.Welpe]: 'Für Welpen (0-12 Monate)',
  [SegmentationIds.Senior]: 'Geeignet für Senioren ( 7+)',
  [SegmentationIds.SkinFur]: 'Unterstützt Haut & Fell',
  [SegmentationIds.Overweight]: 'Ideal bei Übergewicht',
  [SegmentationIds.Bulldog]: 'Ideal für Bulldoggen',
  [SegmentationIds.Chihuahua]: 'Ideal für Chihuahuas',
  [SegmentationIds.Dachshund]: 'Ideal für Dackel',
  [SegmentationIds.Herdingdog]: 'Ideal für Hütehunde',
  [SegmentationIds.Havanese]: 'Ideal für Maltese & Havaneser',
  [SegmentationIds.Poodle]: 'Ideal für Pudel',
  [SegmentationIds.Retriever]: 'Ideal für Retriever-Rassen',
  [SegmentationIds.ShepherdDog]: 'Ideal für Schäferhunde',
  [SegmentationIds.Spitz]: 'Ideal für alle Spitz Rassen',
  [SegmentationIds.Terrier]: 'Ideal für Terrier Rassen',
  [SegmentationIds.CatPickyEater]: 'Gut für wählerische Esser',
  [SegmentationIds.CatDigestion]: 'Ideal bei sensibler Verdauung',
  [SegmentationIds.CatOverweight]: 'Geeignet bei Übergewicht',
  [SegmentationIds.Sterilized]: 'Gut für sterilizierte Katzen',
  [SegmentationIds.Outdoor]: 'Ideal für Freigänger',
  [SegmentationIds.Kitten]: 'Für Kitten (0-1 Jahre)',
  [SegmentationIds.CatSenior]: 'Geeignet für Senioren (10+ Jahre)',
};

type Output = {
  subTitle: null | string;
};

/**
 * Hook to return segmentation related sub title based on tags and user's segmentation
 */
const usePersonalizedSubtitle = ({ tags }: { tags: Array<string> }): Output => {
  const { shouldOmitPersonalization } = useSegmentationContext();
  const { matchedFilterTag } = useShouldPersonalize({ tags });

  const subTitle = useMemo(() => {
    if (!matchedFilterTag || shouldOmitPersonalization) return null;

    const segmentation = SEGMENTS_RAW_FILTER_MAP[matchedFilterTag] as
      | SegmentationIds
      | undefined;

    if (!segmentation) return null;

    return SubtitleMap[segmentation] as string;
  }, [matchedFilterTag, shouldOmitPersonalization]);

  return {
    subTitle,
  };
};

export default usePersonalizedSubtitle;
