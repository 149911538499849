import { ParsedReviews } from 'stores/reviews-cache';

const ratingRegex = /data-average-rating='([\d.]+)'/;
const reviewsRegex = /data-number-of-reviews='([\d.]+)'/;

/**
 * Parse html string to retrieve ratings and reviews
 */
export const parseReviews = ({
  rawReviews,
}: {
  rawReviews: Record<string, string>;
}): ParsedReviews => {
  return Object.entries(rawReviews)
    .map((entry) => {
      const [, _ratingCount] = Array.from(entry[1].match(ratingRegex) || []);
      const ratingCount = _ratingCount;
      const [, _reviewsCount] = Array.from(entry[1].match(reviewsRegex) || []);
      const reviewsCount = _reviewsCount;

      return {
        [entry[0]]: {
          ratingCount,
          reviewsCount,
        },
      };
    })
    .reduce((prev, current) => {
      return {
        ...prev,
        ...current,
      };
    }, {});
};
