import { GlobalDiscount } from 'hooks/common/use-global-discount';
import { EffectiveCart } from 'interfaces/cart';
import { Product } from 'interfaces/product';
import { CollectionProduct } from 'models/collection/collection';
import { StaticPDPProduct } from 'modules/product-details/types';

const hasPersonalizedDiscount = (
  /** @TODO : change it to tags : array<string> */
  product:
    | CollectionProduct
    | StaticPDPProduct
    | Product
    | EffectiveCart['items'][0],
  globalDiscount: GlobalDiscount,
  isSubscription = false
): boolean => {
  if (
    globalDiscount.loading ||
    !globalDiscount.discount ||
    (!isSubscription &&
      globalDiscount.discount &&
      globalDiscount.discount.amountInPercent === 0) ||
    (isSubscription &&
      globalDiscount.discount &&
      globalDiscount.discount.firstSubscriptionOrderDiscount === 0)
  )
    return false;

  if (globalDiscount.discount.productTag) {
    if (
      // If tagType = include, return normal price if tag is not present
      (globalDiscount.discount.tagType === 'include' &&
        product?.tags.indexOf(globalDiscount.discount.productTag) === -1) ||
      // if tagType = exclude, return normal price if tag is present
      (globalDiscount.discount.tagType === 'exclude' &&
        product?.tags.indexOf(globalDiscount.discount.productTag) > -1)
    )
      return false;
  }

  return true;
};

export default hasPersonalizedDiscount;
