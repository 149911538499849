import { create } from 'zustand';

export type ParsedReviews = {
  [productId: string]: {
    ratingCount: string;
    reviewsCount: string;
  };
};

type ReviewsCacheStore = {
  reviews: ParsedReviews;
  setReviews: (updateFunction: (prev: ParsedReviews) => ParsedReviews) => void;
};

/**
 * Zustand Store to cache parsed reviews from JudgeMe API
 */
export const useReviewsCacheStore = create<ReviewsCacheStore>((set) => ({
  reviews: {},
  setReviews: (updateFunction) =>
    set((state) => ({ reviews: updateFunction(state.reviews) })),
}));
