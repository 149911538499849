import { SEGMENTS_RAW_FILTER_MAP } from 'constants/segmentation';
import { useSegmentationContext } from 'contexts/segmentation';
import { useMemo } from 'react';

type Output = {
  /** Matched unslugifyed filter tag */
  matchedFilterTag?: string;
  shouldPersonalize: boolean;
};

/**
 * Hook to check if personalization on PDP level should happen.
 * This hook should be used to filter out inconsistent segmentation data.
 */
const useShouldPersonalize = ({ tags }: { tags: Array<string> }): Output => {
  const { segmentation } = useSegmentationContext();

  const matchedFilterTag = useMemo(() => {
    const entry = Object.entries(SEGMENTS_RAW_FILTER_MAP).find(
      (entry) => entry[1] === segmentation
    );

    if (!entry) return undefined;

    const segmentTag = tags.find((tag) => tag === entry[0]);
    if (!segmentTag) return undefined;

    return segmentTag;
  }, [segmentation, tags]);

  return {
    matchedFilterTag,
    shouldPersonalize: !!matchedFilterTag,
  };
};

export default useShouldPersonalize;
